import {
  FeatureExtractionOption,
  MachineLearningModel,
} from '@/typescript/interfaces';
import { SmartSuiteRouteName } from '@/typescript/enums';

export const SMART_SUITE_APP_FOLDERS = [
  'aoi',
  'categorical',
  'numerical',
  'targets',
  'moving_window_statistics',
  'haralick_texture',
];

export const REQUIRED_SHAPEFILES_EXT = ['shp', 'shx', 'dbf', 'prj'];

export const INITIAL_WINDOW_STATS: FeatureExtractionOption[] = [
  {
    label: 'mean',
    value: 'mean',
    checked: false,
  },
  {
    label: 'max',
    value: 'max',
    checked: false,
  },
  {
    label: 'min',
    value: 'min',
    checked: false,
  },
  {
    label: 'std',
    value: 'std',
    checked: false,
  },
  {
    label: 'range',
    value: 'range',
    checked: false,
  },
  {
    label: 'var',
    value: 'var',
    checked: false,
  },
  {
    label: 'sum',
    value: 'sum',
    checked: false,
  },
];

export const INITIAL_HARALICK_TEXTURES: FeatureExtractionOption[] = [
  {
    label: 'ASM',
    value: 'asm',
    checked: false,
  },
  {
    label: 'Contrast',
    value: 'contrast',
    checked: false,
  },
  {
    label: 'IDM',
    value: 'idm',
    checked: false,
  },
  {
    label: 'Entropy',
    value: 'entr',
    checked: false,
  },
  {
    label: 'DE',
    value: 'de',
    checked: false,
  },
  {
    label: 'MOC1',
    value: 'moc1',
    checked: false,
  },
  {
    label: 'SA',
    value: 'sa',
    checked: false,
  },
  {
    label: 'SE',
    value: 'se',
    checked: false,
  },
  {
    label: 'SV',
    value: 'sv',
    checked: false,
  },
];

export const RESTRICTED_DOMAIN_NAMES = ['categorical', 'targets'];

// Each model has a default iteration value which can be changed by the user
export const MACHINE_LEARNING_MODELS: MachineLearningModel[] = [
  {
    model: 'CatBoost',
    iteration: 30,
  },
  {
    model: 'LightGBM',
    iteration: 50,
  },
  {
    model: 'RandomForest',
    iteration: 30,
  },
  {
    model: 'XGBoost',
    iteration: 50,
  },
];

export const SCALING_METHODS = [
  {
    name: 'Robust',
    value: 'robust_scaler',
  },
  {
    name: 'Standard',
    value: 'standard_scaler',
  },
  {
    name: 'Min-Max',
    value: 'min_max_scaler',
  },
];

export const TRAIN_TEST_RATIO = [
  {
    label: '50:50',
    value: 2,
  },
  {
    label: '67:33',
    value: 3,
  },
  {
    label: '75:25',
    value: 4,
  },
  {
    label: '80:20',
    value: 5,
  },
  {
    label: '90:10',
    value: 10,
  },
  {
    label: '95:05',
    value: 20,
  },
];

export const TRAIN_TEST_RATIO_DEFAULT_VALUE = 4;

export const TRAIN_VALIDATION_FOLDS = [3, 4, 5, 6, 7, 8, 9, 10];

export const TRAIN_VALIDATION_FOLDS_DEFAULT_VALUE = 4;

// Prefix for Coordinate Reference System (CRS)
export const CRS_PREFIX = 'epsg:';

// Geotiff Raster files categorized as targets
export const TARGET_FILES_DOMAIN = 'targets';

// Geotiff Raster files categorized as categorical
export const CATEGORICAL_FILES_DOMAIN = 'categorical';

export const MODELLING_ITERATION = {
  min: 10,
  max: 100,
};

// Modelling Plot Charts
export const MODELLING_PLOTS = {
  OPTIMIZATION_HISTORY: 'plot_optimization_history.json',
  PARAM_IMPORTANCES: 'plot_param_importances.json',
};

// AWS EC2 Instance Types
export const EC2_INSTANCE_TYPES = [
  {
    label: 'm5.2xlarge, vCPUs=8, Memory (GiB) = 32',
    value: 'm5.2xlarge',
  },
  {
    label: 'm5.4xlarge, vCPUs=16, Memory (GiB) = 64',
    value: 'm5.4xlarge',
  },
  {
    label: 'm5.8xlarge, vCPUs=32, Memory (GiB) = 128',
    value: 'm5.8xlarge',
  },
];

// SmartSuite app pages
export const SMARTSUITE_PAGES: Record<SmartSuiteRouteName, string> = {
  [SmartSuiteRouteName.LoadData]: 'BREADCRUMB_DATA_SOURCE',
  [SmartSuiteRouteName.FeatureExtraction]: 'BREADCRUMB_FEATURE_EXTRACTION',
  [SmartSuiteRouteName.TargetsPreparation]: 'BREADCRUMB_TARGETS_PREPARATION',
  [SmartSuiteRouteName.CubeBuilding]: 'BREADCRUMB_CUBE_BUILDING',
  [SmartSuiteRouteName.PCA]: 'BREADCRUMB_PCA',
  [SmartSuiteRouteName.Clustering]: 'BREADCRUMB_CLUSTERING',
  [SmartSuiteRouteName.Modelling]: 'BREADCRUMB_MODELLING',
  [SmartSuiteRouteName.ModellingDashboard]: 'BREADCRUMB_MODELLING_DASHBOARD',
};

// Model Comparison Analysis Plots
export const MODEL_COMPARISON_ANALYSIS_PLOTS = [
  {
    label: 'ROC-AUC',
    model: 'plot_ROC-AUC_curves_intra_model_comparision.json',
  },
  {
    label: 'PR',
    model: 'plot_PR_curves_intra_model_comparision.json',
  },
];

// Model Specific Analysis Plots - Prediction Probability Histogram
export const PREDICTION_PROBABILITY_HISTOGRAM_PLOT = 'plot_histograms.json';

// Model Specific Analysis Plots - Input Feature Relevance
export const INPUT_FEATURE_RELEVANCE_PLOTS = [
  {
    label: 'SHAP values',
    model: 'plot_shap_summary_barplot.json',
  },
  {
    label: 'Feature Importance',
    model: 'plot_feature_importance_barplot.json',
  },
];

// Model Specific Analysis Plots - Per-class Performance
export const PER_CLASS_PERFORMANCE_PLOTS = [
  {
    label: 'Classification Report',
    model: 'plot_classification_report.json',
  },
  {
    label: 'Confusion Matrix',
    model: 'plot_confusion_matrix.json',
  },
];

// Primary Clustering plots and images
export const PRIMARY_CLUSTERING_VISUALS = {
  SOM_HISTOGRAM: 'SOM_histogram.json',
  SOM_UMATRIX: 'SOM_Umatrix.json',
  SOM_FEATURE_WEIGHTS: 'SOM_feature_weights.json',
  SOM_PRIMARY_CLUSTERS: 'SOM_primary_clusters.png',
  SOM_PRIMARY_CLUSTERS_2D_COLORMAP: 'SOM_primary_clusters_2Dcolormap.png',
};

// Secondary Clustering plots and images
export const SECONDARY_CLUSTERING_VISUALS = {
  DBI_PLOT: 'dbi_plot.json',
  CLUSTER_N_BOXPLOTS: (clusterSetting: number) =>
    `cluster_n${clusterSetting}_boxplots.json`,
  SOM_SECONDARY_CLUSTERS: (clusterSetting: number) =>
    `SOM_secondary_clusters_n${clusterSetting}.png`,
  SOM_SECONDARY_CLUSTERS_2D_COLORMAP: (clusterSetting: number) =>
    `SOM_secondary_clusters_2Dcolormap_n${clusterSetting}.png`,
};

// Targets Preparation Aggregation Methods
export const AGGREGATION_METHODS = [
  {
    label: 'Min',
    value: 'min',
  },
  {
    label: 'Max',
    value: 'max',
  },
  {
    label: 'Mode',
    value: 'mode',
  },
];

// Targets Preparation Buffer Styles
export const BUFFER_STYLES = [
  {
    label: 'Circular',
    value: 'circular',
  },
  {
    label: 'Square',
    value: 'square',
  },
];

// SmartSuite Documentation Links
export const SMARTSUITE_DOCUMENTATION_LINKS = {
  Summary:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574292021/Overview',
  SmartCluster:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574357558/SmartCluster',
  SmartMap:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574259245/SmartMap',
  SmartTarget:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574226500/SmartTarget',
  Tips: 'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/573734917/Before+you+start',
  LoadData:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574160975/Load+data',
  FeatureExtraction:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574226512/Feature+extraction',
  TargetsPreparation:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574226529/Target+data+preparation',
  CubeBuilding:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574259262/Cube+building',
  PCA: 'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574259272/PCA',
  Clustering:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574324861/Clustering',
  Modelling:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574292082/Modelling',
  ModellingDashboard:
    'https://goldspot.atlassian.net/wiki/spaces/PRODUCTS/pages/574324844/Modelling+dashboard',
};
