export enum DupeHandling {
  version = 'version',
  overwrite = 'overwrite',
  rename = 'rename',
  skip = 'skip',
}

export enum FileUploadStatus {
  notStarted = 'not started',
  pending = 'pending',
  inProgress = 'in progress',
  done = 'done',
  error = 'error',
  skip = 'skip',
}

export enum SmartSuiteRouteName {
  LoadData = 'load-data',
  FeatureExtraction = 'feature-extraction',
  TargetsPreparation = 'targets-preparation',
  CubeBuilding = 'cube-building',
  PCA = 'pca',
  Clustering = 'clustering',
  Modelling = 'modelling',
  ModellingDashboard = 'modelling-dashboard',
}

export enum SmartSuiteDataType {
  Numerical = 'numerical',
  Categorical = 'categorical',
}

export enum SmartSuiteFileFormat {
  Raster = 'Raster',
  Shapefile = 'AOI Shapefile',
  TrainingTargetRaster = 'Training Target Raster',
  TrainingTargetVector = 'Training Target Vector',
}

export enum SmartSuiteTargetFileType {
  Targets = '_targets',
  GroupIDs = '_groupIDs',
}

export enum SmartSuiteFileExtensions {
  tif = '.tif',
  tiff = '.tiff',
  shp = '.shp',
  shx = '.shx',
  dbf = '.dbf',
  prj = '.prj',
  cpg = '.cpg',
}

export enum SmartSuiteJobType {
  FeatureExtraction = 'smartsuite.feature_extraction',
  CubeBuilding = 'smartsuite.cube_building',
  PCA = 'smartsuite.pca',
  BaselineModelling = 'smartsuite.baseline_modelling',
  TuningModelling = 'smartsuite.tuned_modelling',
  Clustering = 'smartsuite.clustering',
  TargetsPreparation = 'smartsuite.targets_preprocessing',
}

export enum FeatureExtractionOptionCategory {
  WindowStats = 'stats',
  HaralickTextures = 'method',
}

export enum ClusteringType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum Panel {
  CubeDetails = 'Cube Details',
  InputLayers = 'Input Layers',
  TuningReport = 'Tuning Report',
  ModelComparisonAnalysis = 'Model Comparison Analysis',
  ModelSpecificAnalysis = 'Model Specific Analysis',
  RasterVisualizer = 'Raster Visualizer',
  ClusteringResults = 'Clustering Results',
  ClusteringVisualization = 'Clustering Visualization',
  CubeJobsList = 'Cube Jobs List',
  PCAJobsList = 'PCA Jobs List',
  PCAAnalysis = 'PCA Analysis',
}

export enum JobStatus {
  Done = 'done',
  InProgress = 'in-progress',
  Requested = 'requested',
  Todo = 'todo',
}

export enum JobResult {
  Successful = 'successful',
  Failed = 'failed',
}
